.advanced-search {
  width: 100%;

  &__title {
    margin: $spacing-6x 0 $spacing-5x;
  }

  &__tab-content {
    padding: $spacing-2x;
  }

  .ccl__field-control:last-child {
    min-width: inherit;
  }

  .ccl-e-image {
    width: $spacing-10x;
    margin: auto;
  }

  .ccl-locate-patient__table {
    margin-top: $spacing-6x;

    .ccl-e-table--spinner {
      .ccl__text {
        font-family: 'Circular-Medium';
      }
    }

    .ccl__dpx-error {
      border-top: 1px solid $color-base-neutrals-brandcoolgrey-75;
      padding-top: $spacing-10x;
    }

    .ccl__no-results {
      margin-top: $spacing-10x;
      padding-top: $spacing-10x;
      border-top: 1px solid $color-base-neutrals-brandcoolgrey-75;
      max-width: none;

      .ccl-e-formatted-composite {
        font-size: 1.125rem;
        font-family: 'Circular-Regular';
      }
    }
  }
}

@import './tabs/patient.scss';
@import './modals/agreementModal.scss';
