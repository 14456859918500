.ccl__data-table--device-registration{
.ccl-e-table {
    $this: &;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    // style table cells
    &__thead__tr__th,
    &__tbody__tr__td {
      border: 1px solid $color-lightmode-semantic-typography-brand;
      border-width: 0 0 1px;
      padding: 0.25em 0.25em;
      vertical-align: bottom;
      @include tablet {
        padding: 0.5em;
      }
    }
    &__tbody__tr__td {
      padding: 0.5em 0.25em;
      border-color: $color-lightmode-semantic-interactiveforeground-neutralsubtle;
      @include tablet {
        padding: 0.75em 0.5em;
      }
    }
  
    &__thead {
      &__tr {
        &__th {
          &--restrict-width {
            width: 1%;
            white-space: nowrap;
          }
        }
      }
  
      // column header
      &__column-title {
        display: flex;
        align-items: center;
        color: $color-lightmode-semantic-typography-brand;
        font-size: 0.875rem; /* 14/16 */
        font-family: $textstyle__base__family-secondary;
        font-weight: 500;
        text-align: left;
        line-height: 1.285714285714286; /* 18px/14 */
        white-space: nowrap;
        @include tablet {
          font-size: 1.125rem; /* 18/16 */
          line-height: 1.555555555555556; /* 28px/18 */
        }
        // if the arrow for sorting exists
        .ccl__icon {
          margin-left: $spacing-1x;
        }
      }
    }
  
    // table body data
    &__tbody {
      &__tr {
        &--hover {
          &:hover {
            background-color: $color-lightmode-semantic-background-highlightsubtle;
          }
        }
        &--highlight {
          background-color: #fefcf6;
        }
        // style odd rows inside table body
        &--highlight-odd {
          &:nth-child(odd) {
            background-color: #fefcf6;
          }
          &:last-child {
            .ccl-e-table__tbody__tr__td {
              border-color: $color-lightmode-semantic-interactiveforeground-bold;
            }
          }
        }
        // style table cell text
        &__td {
          color: $color-lightmode-semantic-typography-brand;
          font-size: 0.875rem; /* 14/16 */
          font-family: $textstyle__base__family-primary;
          font-weight: normal;
          line-height: 1.285714285714286; /* 18px/14 */
          vertical-align: middle;
  
          @include tablet {
            font-size: 1rem; /* 16/16 */
            line-height: 1.5; /* 24px/16 */
          }
  
          .status {
            white-space: nowrap;
          }
          &--group {
            border-color: transparent;
          }
          &--parent {
            border-color: transparent;
            vertical-align: top;
          }
          &--child {
            vertical-align: top;
          }
          // &--group {
          //   // @extend .ccl__text--informative-text;
          //   border-color: transparent;
          //   // @extend .ccl__title--heading-3;
          // }
        }
      }
    }
  
    &--list {
      #{$this}__tbody {
        &__tr {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  
    // details view
    &--details {
      #{$this}__tbody {
        &__tr {
          &__td {
            vertical-align: middle;
          }
        }
      }
    }
  
    &--spinner {
      text-align: center;
      margin-top: $spacing-10x;
      .spinner-border {
        border: 1px solid
          $color-lightmode-semantic-interactiveforeground-neutrallite;
        border-right-color: transparent;
      }
    }
  
    &__loadMore {
      text-align: center;
      margin-top: 1rem;
      .spinner-border {
        border: 1px solid
          $color-lightmode-semantic-interactiveforeground-neutrallite;
        border-right-color: transparent;
      }
    }
  }
  
  .ccl__no-results {
    text-align: center;
    max-width: 28.125rem;
    margin: auto;
    &--additional {
      .ccl__text {
        display: inline;
      }
      .link {
        cursor: pointer;
      }
    }
  }
  
  .ccl__results-limit {
    text-align: center;
    max-width: 28rem;
    margin: 4rem auto;
    &--additional {
      .ccl__text {
        display: inline;
      }
      .link {
        cursor: pointer;
      }
    }
  }
  
  .ccl-e-link--text {
    cursor: pointer;
    .ccl__text {
      display: inline;
    }
  }
  
  .hidden-mobile {
    display: none;
  }
  
  @include tablet {
    .hidden-tablet {
      display: none;
    }
    .hidden-mobile {
      display: table-cell;
    }
  }
  
  .ccl__icon{
    display: inline-block;
  }
  
  // temporary for the link button
  // this will be added eventually to the ccl as part of the buttons component
  .ccl-e-button {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: transparent;
    &--text {
      color: $color-lightmode-semantic-baseline-digitalprimary;
      font-size: 16px;
      font-family: $textstyle__base__family-secondary;
      font-weight: 500;
      line-height: 24px;
    }
    &--align-end {
      justify-content: flex-end;
    }
  }
  
  .ccl-e-keyvalue {
    display: flex;
  }
  
  .ccl__results-heading {
    margin: 1.2rem 0;
  }
  
}