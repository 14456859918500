@import './variables';

// cds base
@import '@cochlear-design-system/styling/dist/cds';
@import './SelectClinic/clinicList';
@import './Footer/footer';
@import './Header/header';
@import './Header/topnav';
@import './Dropdown/dropdown';
@import './Search/simpleSearch';
@import './AdvancedSearch/advancedSearch';
@import './PatientsWrapper/patients';
@import './MainPatientWrapper/mainPatientWrapper';
@import './Error/error';
@import './CreatePatient/landing';
@import './DeviceRegistrationWrapper/DataTable';
@import './DeviceRegistrationWrapper/DeviceRegistration';
@import './DeviceRegistrationReviewWrapper/DeviceRegistrationReview';
@import './TaskBar/taskBar';

#page-container {
  position: relative;
  min-height: 100vh;
}

#main-content {
  padding-bottom: 5rem;
}

.ccl__spinner {
  text-align: center;
  margin: 5rem auto;

  .spinner-border {
    border: 1px solid
      $color-lightmode-semantic-interactiveforeground-neutrallite;
    border-right-color: transparent;
  }
}

.ccl__align {
  &-cm5 {
    text-align: center;
    margin: 5rem;
  }

  &-cm2 {
    margin: 2rem;
  }
}

.ccl__data-table--dpx {
  &--equipment {
    th:nth-child(1) {
      width: 40px;
    }
    th:nth-child(3) {
      width: 33%;
    }

    th:nth-child(4) {
      width: 33%;
    }
  }

  .ccl-e-table__thead__tr__th {
    border-width: 0 0 2px;
    background-color: transparent;
  }

  .ccl-e-table__tbody__tr {
    &__td {
      vertical-align: top;
    }

    &--highlight {
      background-color: $color-lightmode-semantic-background-highlightsubtle;
    }

    &:hover {
      background-color: $color-lightmode-semantic-background-highlightsubtle;
    }
  }
}

.ccl__icon {
  display: block;
}

.ccl__create-patient-wrapper {
  [data-nw-about-me-section='accountDetailsView'] {
    .ccl-e-detail-action-bar__action {
      display: none;
    }
  }
}
