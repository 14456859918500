@charset "utf-8";

@import '@cochlear-design-system/foundation/dist/index.css';
@import '@cochlear-design-system/features.universalHeader/dist/index.css';
@import '@cochlear-design-system/features.dataTable/dist/index.css';
@import '@cochlear-design-system/features.adaptiveForms/dist/index.css';
// @import '@cochlear-design-system/projects.dpx/dist/index.css';
@import '../ui/index.scss';
@import './localisation-long-text.scss';
@import './localisation-short-text.scss';

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

$grey: #cccccc;
$grey-light: #efefef;
$yellow: #fdc82f;

// Spinner (page/section spinner)
// --------------------------------------------
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100vh;
  background: $grey-light;
  .spinner__content {
    text-align: center;
    .spinner__text {
      color: rgb(51, 74, 106);
      font: 400 1em/1.5 'Circular-Medium';
      text-align: center;
    }
    .fader {
      transition: opacity 2.5s linear;
      &.fadedOut {
        opacity: 0;
      }
    }
  }
  .spinner__loading {
    margin: 0 auto;
    display: block;
    width: 46px;
    height: 46px;
    &:after {
      @include animation('lds-dual-ring 1.2s linear infinite');
      content: ' ';
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $grey;
      border-color: $grey transparent $grey transparent;
    }
  }
}

@include keyframes(lds-dual-ring) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Navbar
.navbar {
  border-top: 5px solid $yellow;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.navbar-item {
  padding: 20px;
}

.navbar--items {
  padding: 0;
  align-items: center;
  display: flex;
  min-height: 3.25rem;
  margin: auto;
}

.navbar-item img {
  max-height: 1.3rem;
}

.dpx {
  &__main-content {
    padding-top: 3rem;
  }

  &__select-clinic {
    width: 80%;
    margin: 4rem auto;
  }

  &__invite_modal_input {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .ccl__form-input__error-message.ccl__text.ccl__text--error-small-text {
      display: flex;
      align-items: center;
    }
  }
}

.dpx__container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 5rem;
}

// temporary
.ccl-e-icon-clickable {
  &--iconButton {
    .ccl-e-icon-clickable__button {
      &--size--large {
        min-width: 48px;
        min-height: 48px;
      }

      &--brand-primary {
        background-color: #f1be48;
        border-radius: 10em;
        border: none;
        &:hover {
          background-color: #f4b013;
        }
        &:focus,
        &--active {
          background-color: #f1be48;
        }
      }

      &--plain {
        border-radius: 10em;
        &:hover,
        &:focus,
        &--active {
          background-color: #e1d5f4;
        }

        &:disabled {
          background-color: #d6d6d6;
        }
      }
    }
  }
}

// temporary
.ccl-e-custom-dropdown {
  &--content-menu-dropdown {
    justify-content: right;
  }
  &--content-menu-dropdown &__container__list-items {
    padding: 0px;
    margin-top: 8px;
    border: 0px;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);
    width: 294px;

    &__item {
      border-top: 1px solid #ebebeb;
      background-color: #ffffff;

      &:hover {
        background-color: #ebebeb;
      }

      &:active {
        background-color: #faf9f6;
      }

      &__button {
        .ccl__icon {
          &--color--interactive {
            background-color: #384967;
            min-width: 20px;
            margin-top: 4px !important;
          }
        }
        padding: 16px 20px;

        &--small {
          padding: 16px;

          .content-menu__icon__text {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.ccl__container {
  max-width: 1696px;
}

.modal-dialog.modal-xl {
  max-width: 1000px;
}

#rc__container {
  position: relative;
}

.ccl-l-tabs__content__inner {
  min-height: 300px;
}

#backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
}

#rcbridge {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 15px;
  z-index: 3;
  height: 31.25rem;
}

.cp-adaptive-forms {
  .ccl-e-detail-action-bar {
    margin-top: 20px;
  }
  .ccl-e-detail-action-bar__title {
    font-size: 1.75rem;
    margin-top: 10px;
  }
}

.device-registration {
  .ccl-e-table__tbody__tr {
    cursor: pointer;
  }
}
.ccl__register-review {
  .ccl-e-detail-action-bar {
    margin-top: 20px;
  }
  .ccl-e-detail-action-bar__title {
    font-size: 1.75rem;
    margin-top: 10px;
  }
  .ccl__form-datepicker-label {
    margin-bottom: 0.5rem;
  }
  .ccl-l-userTabs__content__inner
    > .ccl-c-form-section:first-child
    .ccl-e-detail-action-bar {
    margin-top: 0;
  }
  .ccl__clincsPopup {
    button:disabled,
    button[disabled] {
      display: none;
    }
  }
  .react-datepicker__aria-live {
    display: none;
  }
  .ccl__form-input.ccl__form-input--is-radio-input {
    display: inline-block;
    width: auto;
    margin-right: 1rem;
  }
}

.ccl__data-table--dpx {
  .ccl-e-table__tbody__tr__td {
    &--show-content {
      &:first-child {
        border-top-width: 1px;
      }
    }
  }
}

.modal {
  z-index: 2147483628;
}

.ccl__AssociatedClinicPopup #typeahead {
  display: none !important;
}

#loaner-label {
  display: block;
  background: #fdf3dc;
  width: 83px;
  height: 26px;
  padding: 7px 8px 7px 25px;
  border-radius: 12px;
  font-family: 'Circular-Medium';
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  font-style: normal;
  color: #404042;
}

.ccl__create-patient-wrapper
  .ccl__clincsPopup
  .ccl__clincsPopup__DataTable {
  display: none;
}
.ccl__create-patient-wrapper .ccl-e-detail-action-bar__title {
  font-size: 1.75rem;
}

.ccl__flex {
  display: flex;
}

.add-clinic[disabled] {
  display: none;
}

.matching-records-table tbody tr {
  cursor: pointer;
}

.ccl__edit-patient-wrapper {
  width: 80%;
  margin: 0 auto;

  .edit-patient__title {
    margin-top: 5rem;
  }

  .ccl__page-header__title {
    margin-bottom: 1.5rem;
  }
  .ccl__text--link-small-text {
    cursor: pointer;
    color: #3f1482;
    font-family: 'Circular-Medium', 'Circular-Regular',
      'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
  }

  .preloader--line {
    max-width: none;
    width: auto;
  }

  .ccl-l-edit-patient-tab__fields__name,
  .ccl-l-edit-patient-tab__fields__dob,
  .ccl__form-input--is-phone-input {
    margin: 1rem 1rem 0 0;
    width: 345px;
  }

  .ccl__form-input--date-input {
    .ccl__field-control:last-child {
      min-width: unset;
    }
  }

  .ccl-l-edit-patient-tab__actions {
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;
    width: 345px;
  }

  .ccl__form-input--is-phone-input .ccl__field-control__input {
    padding-right: 0.25rem;
  }

  .ccl_address_list_item {
    border-bottom: #cfcfd0 1px solid;
    padding-bottom: 0.25rem;
  }
}

.ccl-l-create-patient__fields {
  &__name {
    margin: 1.75rem 1rem 0 0;
    width: 370px;
  }
}

.ccl__register-review {
  .ccl__button--save--has-saved {
    background-color: #f1be48 !important;
    color: #3f1482 !important;
  }
}

.ccl__add-carer-wrapper {
  width: 80%;
  margin: 0 auto;

  .add-carer__title {
    margin-top: 5rem;
  }

  .ccl__page-header__title {
    margin-bottom: 1.5rem;
  }

  .preloader--line {
    max-width: none;
    width: auto;
  }
}

@media print {
  .ccl__nav-bar__logo {
    margin-left: 75px;
    margin-top: 30px;
  }
  .ccl__register-review .ccl-l-userTabs__content {
    padding: 20px 40px;
  }
}

.ccl__create-patient-wrapper {
  width: 80%;
  margin: 0 auto;

  &__title {
    margin: 0;
  }
  .ccl__page-header__title {
    margin-bottom: $spacing-3x;
  }
  .create-patient-review__title {
    margin-top: $spacing-10x;
  }
  .ccl__view-read-mode--row {
    .ccl__read-mode {
      dt {
        min-width: 10rem;
        max-width: 10rem;
      }
    }
    dl {
      margin: 0;
    }
    .ccl-e-read-mode-composite__element:first-child {
      margin: 0;
    }
  }
  .ccl-c-form-section {
    .ccl__view-read-mode--row {
      &:nth-of-type(2) {
        margin-top: 1rem;
      }
    }
  }
}
.ccl__Create-patient-wrapper__AssociatedClinicPopup .modal-footer {
  flex-direction: row-reverse;
  .ccl__button {
    margin-right: 20px;
  }
}
.offcanvas.show {
  z-index: 9999999999 !important;
}

.ccl__nav-bar {
  &__dpx__my-profile--wrapper {
    z-index: 9999999999 !important;
  }
}

.alert__content {
  white-space: pre-wrap;
}

.ccl__dpx_center {
  &-600 {
    margin: 3rem auto;
    width: 600px;
  }
}

.update-accessory-modal {
  > div {
    max-width: 50% !important;
  }
  .details-view {
    margin: $spacing-5x 0;
  }
}
.ccl__dpx_patient-wrapper {
  .ccl__data-table--dpx {
    .ccl__results-limit {
      max-width: 40rem;
    }
  }
}
.notification-link {
  min-height: 0;
  margin: 0 !important;
  padding: 0;
}
.notification-message-item {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  color: #384967;
}
.notification-link > span {
  font: 400 1.125rem/1.5555555556 'Circular-Regular' !important;
  padding: 0 !important;
  text-decoration: underline;
}

.label-spacing,
.ccl__register-review .ccl-e-read-mode-composite__element dl > dt {
  min-width: 8rem;
  margin-right: 1rem;
}
