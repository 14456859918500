.taskbar {
  &-row {
    align-items: center;
    text-align: left;
  }

  &-col {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
  }
}
