.ccl__topNav {
  border-bottom: 2px solid
    $color-lightmode-semantic-interactiveforeground-neutralsubtle;

  &--container {
    display: flex;
    z-index: 2147483628;
  }

  p {
    margin: 0;
  }

  .ccl-e-icon-label {
    justify-content: space-between;
    &__text {
      padding: 0;
      margin-right: 1rem;
    }
  }
}
