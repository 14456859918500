.ccl__register-review{
    width: 80%;
    margin: 0 auto;
  
    &__title {
      margin: 0;
    }
    .ccl__page-header__title{
        margin-bottom: $spacing-3x;
    }
    .device-registration__user-name{
        margin-top: $spacing-10x;
    }
    .ccl__view-read-mode--row{
        .ccl__read-mode{
            dt{
                min-width: 10rem;
                max-width: 10rem;
            }
        } 
        dl{
            margin: 0;
        }
        .ccl-e-read-mode-composite__element:first-child{
            margin: 0;
        }
    } 
    .ccl-c-form-section{
        .ccl__view-read-mode--row{
            &:nth-of-type(2){
                margin-top: 1rem;
            }
        }
    } 
    
}
.ccl__Register_Review_AssociatedClinicPopup .modal-footer{
    flex-direction: row-reverse;
    .ccl__button{
        margin-right: 20px;
    }
}
/* This is the print-friendly CSS */
    /* Hide elements that are not needed in print version */
@media print {
    /* Hide elements that are not needed in print version */
    .ccl__dpx-footer, .ccl__form-button, .row .col-12 .alert, .device-registration__user-name, .ccl__nav-bar__dpx__menu__start .ccl__nav-bar__dpx__separator, .ccl__nav-bar__dpx__menu__start .ccl__nav-bar__dpx__appName, .ccl__nav-bar__dpx__menu__end .ccl__linktag {
        display: none;
    }
    .ccl__register-review .ccl__page-header__title {
        margin-top: 20px;
    }
    .ccl__a .ccl-l-userTabs--single {
        border: 1px solid black;
    }
    .ccl-l-userTabs {
        box-shadow: 0px 2px 4px 0px rgba(64, 64, 66, 0.5);
    }
    .ccl__nav-bar__dpx__logo {
        margin-left: 60px;
        margin-top: 30px;
    }
    .ccl__nav-bar__dpx {
        height: auto;
        border-top: 0;
    }
}
.device-registration-close{
    .modal-content{
        top:200px;
    } 
}