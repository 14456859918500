.ccl-l-advanced-search-patient-tab {
  &__searchOptions,
  &__fields {
    padding: $spacing-4x;
  }

  &__patientIdentifier {
    margin: $spacing-1x 0;
  }

  &__fields {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    &__or {
      margin: 4 * $spacing-2x $spacing-3x 0;
    }

    &__name {
      margin: $spacing-3x + $spacing-half $spacing-2x 0 0;
      width: 320px;

      label.ccl__form-input__label {
        display: inline;
      }

      .ccl__form-input__hint {
        display: inline;
        margin-left: 5px;
      }
    }

    &__deviceSerial {
      margin: $spacing-2x 0 $spacing-2x 0;
      width: 320px;
    }

    &__accountUsername {
      margin: $spacing-2x 0 $spacing-2x 0;
      width: 450px;

      .ccl__field-control {
        width: 320px;
      }
    }

    &__dob {
      margin: $spacing-2x 0;
      width: 330px;
    }

    &__associated-clinic {
      margin: $spacing-2x $spacing-1x $spacing-4x $spacing-4x;

      &__change {
        cursor: pointer;
        display: inline;
      }

      &__address {
        display: inline;
        margin-right: 1rem;
      }
    }

    &__submit {
      &--disabled {
        cursor: not-allowed;
      }

      &__button {
        &:disabled {
          pointer-events: none;
        }
        margin-top: 1rem;
      }

      &__tooltipText {
        color: $color-lightmode-semantic-typography-inverse;
      }
      margin-top: $spacing-7x;
    }
  }
}

.ccl-locate-patient__table {
  .ccl-e-table--spinner {
    .ccl__text {
      font-family: 'Circular-Medium';
    }
  }
}
