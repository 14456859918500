.ccl__dpx-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $color-lightmode-semantic-background-brand-01;
  padding: 10px;

  .list-group-item {
    background-color: transparent;
    border-style: none;

    a {
      text-decoration: none;

      &:hover {
        .ccl__text {
          color: $color-lightmode-semantic-typography-inverse;
          text-decoration: underline;
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .ccl__text {
    color: $color-lightmode-semantic-typography-inversesecondary;
  }

  .row > div:last-child {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 30px;
  }
  p {
    margin-bottom: 0;
  }

  &--end {
    text-align: end;
  }
}
