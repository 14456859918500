.ccl__container.device-registration-wrapper {
  display: block;
  .cancel-button {
    margin: 40px 0 0 10%;
  }
}
.device-registration {
  width: 80%;
  margin: 0 auto;

  &__title {
    margin: 0;
  }
  .ccl__page-header__title {
    margin-bottom: $spacing-5x;
  }
  .device-registration__user-name {
    margin-top: $spacing-10x;
  }

  &__tab-content {
    padding: $spacing-3x 0;
  }
  .ccl-l-device-registration-patient-tab {
    &__fields {
      &__device-serial {
        margin: $spacing-2x + $spacing-half $spacing-2x 0 0;
        width: 30%;
        float: left;
      }
      &__submit__button {
        float: left;
        margin-top: 79px;
        padding: 0 40px;
      }
      &__clear {
        clear: both;
      }
    }
  }

  .ccl__data-table--device-registration {
    margin-top: 50px;
  }

  .ccl-e-table--spinner {
    text-align: center;
    margin: 0;
    display: inline-flex;
    .spinner-border {
      border: 2px solid
        $color-lightmode-semantic-interactiveforeground-neutrallite;
      border-right-color: transparent;
      margin-left: $spacing-1x;
    }
  }
  .ccl__dpx-error {
    margin-top: 0;
  }
  .ccl-c-error-message {
    width: 65%;
    margin: 50px 0 0 0;
    .ccl__title.ccl__title--heading-3 {
      margin-bottom: 20px;
    }
    .ccl-c-error-message__image {
      margin-top: 60px;
    }
  }
  .api-error {
    .ccl-c-error-message {
      width: 65%;
      margin: 50px 0 0 0;
      .ccl__title.ccl__title--heading-3 {
        margin-bottom: 20px;
      }
      .ccl-c-error-message__image {
        margin: 15px 25px 0 0;
      }
    }
  }
}

.device-registration-close {
  .modal-content {
    top: 200px;
  }
}

.device-registration-find-by-radio-group {
  display: inline-block;
  .ccl__form-input--is-radio-input {
    width: auto;
    float: left;
    margin-right: 20px;
  }
}

.no-name-margin {
  margin-top: 80px;
}

.accessory-select {
  width: 60%;
  height: 114px;
}
