.ccl__dpx-list {
    .ccl__list-group {
      border-left: 1px solid $color-lightmode-semantic-interactiveforeground-neutralsubtle;
  
      .ccl__list-item {
        border-bottom: 1px solid $color-lightmode-semantic-interactiveforeground-neutralsubtle;
        border-left: 3px solid transparent;
        padding: 10px;
  
        &:hover {
          background-color: $color-lightmode-semantic-background-defaultsubtle;
          border-left: 3px solid $color-lightmode-semantic-baseline-brandprimary;
          cursor: pointer;
        }
  
        .row > div:last-child {
          display: flex;
          justify-content: right;
          align-items: center;
        }
  
        p {
          margin-bottom: 0;
        }
      }
    }
  }