.ccl__nav-bar__dpx {
  height: 5rem;
  display: flex;
  &__menu {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &__start {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
    &__end {
      height: 100%;
      flex-grow: 1;

      .ccl__my-profile {
        z-index: 1;
        &-menu__link:hover {
          background-color: $color-lightmode-semantic-background-shade;
        }
        &-button {
          height: 100%;
          background-color: transparent;
          &__active {
            background-color: #fff;
          }
        }
        &__button {
          height: 100%;
        }
        &__menu {
          width: 245px;
        }
        &-menu {
          padding: 0;

          &__list {
            padding-left: 0;
            &__item {
              list-style-type: none;
            }
          }
        }
      }
    }
  }

  &__clinic-selector {
    height: 100%;
    &--single-item {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }

  &__logo {
    max-height: 40px;
    width: 10rem;
  }

  &__appName {
    margin-left: 1rem;
  }

  &__separator {
    border-left: 2px solid
      $color-lightmode-semantic-interactiveforeground-neutralsubtle;
    height: 27px;
    width: 1px;
    margin-left: 1rem;
  }

  &__my-profile--wrapper {
    display: flex;
    height: 100%;
  }

  &__closeLink {
    color: $color-lightmode-semantic-baseline-digitalprimary;
    text-decoration: underline;
    text-underline-position: under;
  }

  .ccl__title {
    color: $color-lightmode-semantic-typography-secondary;
    margin: 0;
  }
  .col {
    height: 100%;
  }
}

.ccl__container {
  align-items: center;
  display: flex;
}
