.ccl__dropdown {
  position: relative;
  height: 100%;
  z-index: 2147483628;
  &__toggle {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 1rem;
    &:hover {
      background-color: $color-lightmode-semantic-background-shadestrong;
    }
    p {
      margin-bottom: 0;
    }
    &__icon {
      margin-left: 0.25rem;
    }
    &--active {
      background-color: $color-lightmode-semantic-icon-inverse;
      &:hover {
        background-color: $color-lightmode-semantic-icon-inverse;
      }
    }
  }

  &__list {
    position: absolute;
    right: 0px;
    min-width: 350px;
    @include desktop {
      padding: 0;
      box-shadow: 0px 10px 15px 0 #dfdfdf;
    }
    &__item {
      cursor: pointer;
      border-top: 1px solid
        $color-lightmode-semantic-interactiveforeground-neutralsubtle;
      background-color: $color-lightmode-semantic-icon-inverse;
      list-style-type: none;
      padding: 1rem 1rem;
      &:hover {
        background-color: $color-lightmode-semantic-background-shade;
      }

      &--selected {
        background-color: $color-lightmode-semantic-background-highlightsubtle;
        .ccl__dropdown__list__item__container--icon {
          visibility: visible;
          margin: 0 0.5rem;
        }
        &:hover {
          background-color: $color-lightmode-semantic-background-highlightsubtle;
        }
      }
      &__container {
        display: flex;
        align-items: center;
        &--icon {
          visibility: hidden;
          margin: 0 0.5rem;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}
