.ccl__main-patient {
  margin-top: $spacing-6x;
  width: 100%;

  &--header {
    display: flex;
    margin-bottom: 1rem;

    .ccl-e-icon-clickable {
      align-items: center;
    }

    .ccl__page-header {
      margin-right: 1rem;

      &__title {
        margin-bottom: 0;
      }
    }
  }
}
.ccl__af--coolgrey {
  .preloader--line {
    width: 100%;
  }
}

.ccl-c-error-message__image img {
  width: 120px;
}

/* fix for bug in Alert which always renders a last "." */
span.alert__footer__item:last-child {
  display: none;
}
