/* a[target='_blank']:after {
  content: '\1F5D7'
} */

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}
.device-registration .ccl-e-detail-action-bar__title span,
.ccl__title--heading-5 {
  font-size: 22px;
}

.device-registration .ccl-e-detail-action-bar {
  margin-bottom: 20px;
}

.device-registration .ccl__read-mode {
  margin: 0;
}

.device-registration .ccl__title--heading-5 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.device-registration .ccl-e-read-mode-composite__element {
  margin-top: 0;
}

.device-registration .ccl__view-read-mode--row .ccl__read-mode dt {
  min-width: 11rem;
}

dl[data-nw-rmc-label='surgeryCentreAddress'] dt {
  visibility: hidden;
}

.header-nav .container {
  display: block;
}

.header-nav .navbar--items {
  justify-content: center;
}

.header-nav .navbar--items .navbar-item--brand {
  display: flex;
  justify-content: center;
  flex: 1;
}

.header-nav .navbar--items .close-link {
  padding-left: 10px;
}

.dpx__summary-patient .message-blurb {
  background-color: #def1d9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.dpx__summary-patient .secondary-button {
  margin: 8px 20px;
}

.dpx__summary-patient .button-link {
  text-decoration: none;
}

.dpx__summary-patient .ccl__icon--check-circle {
  margin-right: 10px;
}
.ccl__register-review
  .ccl__clincsPopup
  .mt-3
  + .ccl__edit-mode__element
  .ccl__clincsPopup__DataTable {
  display: none;
}

.async-notification .badge-container {
  position: fixed;
  right: 20px;
  bottom: 6%;
}

.async-notification .badge-container .count-badge {
  position: fixed;
}
.async-notification
  .badge-container
  .ccl-e-icon-clickable--iconButton
  .ccl-e-icon-clickable__button--brand-primary:disabled:hover {
  background-color: #d6d6d6;
  cursor: no-drop;
}

.async-notification .clickable-text {
  cursor: pointer;
  display: inline;
}

.async-notification .body-text {
  display: inline;
}

.async-notification .close-button {
  position: absolute;
  top: 5%;
  right: -5px;
}

.async-notification .list-group-item {
  padding: 0.25rem 1rem 0.25rem 0;
}

.ccl__qr-code-container .qr-code-page-title {
  text-align: center;
  margin: 25px auto;
}
.ccl__qr-code-container .qr-code-page-title-print {
  display: none;
}

.ccl__qr-code-container .qr-code-wrapper {
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 3px 0px #f1be48, inset -1px 0px 0px #cfcfd0,
    inset 0px -1px 0px #cfcfd0, inset 1px 0px 0px #cfcfd0;
  width: 50%;
  margin: inherit;
  text-align: center;
}

.ccl__qr-code-container .qr-code-wrapper .qr-code-title {
  margin: 2rem auto auto auto;
}

.ccl__qr-code-container .qr-code-wrapper .qr-code {
  margin: 2rem auto;
}
.ccl__qr-code-container .qr-code-wrapper .qr-code-body-print {
  display: none;
}

.ccl__qr-code-container .qr-code-wrapper .qr-code-print-btn {
  text-align: right;
}
.ccl__qr-code-container .qr-code-wrapper .qr-code-copy-link-btn {
  text-align: left;
}
.ccl__qr-code-container .qr-code-wrapper .qr-code-open-link-btn {
  margin-bottom: 1rem;
}
.ccl__qr-code-container .qr-code-alert-wrapper {
  position: absolute;
  right: 0.5rem;
  bottom: 2.5rem;
}
.ccl__qr-code-container .qr-code-select-surgery-centre {
  cursor: pointer;
}

.ccs-form-wrapper .ccs-form-webReason-wrapper {
  max-width: 50%;
}

.ccs-form-wrapper .ccs-form-description-wrapper {
  margin-bottom: 3rem;
  padding-top: 3rem;
}
.ccs-modal-footer {
  justify-content: flex-start;
}

.report-issue-form-wrapper .report-issue-form-description-wrapper {
  margin-bottom: 2rem;
}
.report-issue-group {
  margin-bottom: 1rem;
}

.report-issue-group .report-issue-section-title {
  margin-bottom: -1rem;
}
.report-issue-group .report-issue-section-row {
  margin-bottom: -1rem;
}

.report-issue-modal-footer {
  justify-content: flex-start;
  padding-top: 1rem;
}
@media print {
  .ccl__qr-code-container .qr-code-page-title {
    display: none;
  }
  .ccl__qr-code-container .qr-code-page-title-print {
    margin-top: 5rem;
    margin-left: 3.5rem;
    display: inline-block;
  }
  .ccl__qr-code-container .qr-code-wrapper {
    text-align: left;
    width: 100%;
    box-shadow: none;
    margin-left: 3.5rem;
  }
  .ccl__qr-code-container .qr-code-wrapper .qr-code-body-print {
    display: inline-block;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  .ccl__qr-code-container .qr-code-wrapper .qr-code-title {
    display: none;
  }
  .ccl__qr-code-container .qr-code-wrapper .qr-code-print-btn {
    display: none;
  }
  .ccl__qr-code-container .qr-code-wrapper .qr-code-copy-link-btn {
    display: none;
  }
  .ccl__qr-code-container .qr-code-wrapper .qr-code-open-link-btn {
    display: none;
  }
  .ccl__qr-code-container .qr-code-alert-wrapper {
    display: none;
  }
  .ccl__qr-code-container .qr-code-select-surgery-centre {
    display: none;
  }
  .ccl__qr-code-container .qr-code-surgery-centre-na {
    display: none;
  }
}
