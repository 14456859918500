.ccl__data-table--dpx {
  width: 100%;

  .ccl-e-image {
    background-color: transparent;
    width: 5.25rem;
    margin: auto;
  }

  .ccl__icon--chevron-right {
    cursor: pointer;
  }
}

.ccl__dpx_patient-wrapper {
  .ccl__dpx-error {
    margin: auto;

    .ccl-c-error-message {
      &__button-control {
        display: none;
      }
      &__content {
        display: flex;
      }
      &__text {
        margin: 1rem 0 0 1rem;
      }
    }
  }
}
