.ccl__simple-search {
  width: $spacing-1x * 87.5; // 43.75rem;
  margin: $spacing-6x auto;

  .ccl__button {
    margin-top: $spacing-half;
  }

  .ccl__field-control__input {
    padding-left: $spacing-5x + $spacing-half;
  }

  .ccl__text--body-text {
    &::placeholder {
      opacity: 0.5;
    }
  }
  &--search-box {
    height: $spacing-10x;
  }

  &--search-input {
    display: flex;
  }

  &--search-icon {
    position: absolute;
    z-index: 1;
    padding-left: $spacing-2x;
    padding-top: $spacing-2nhalf;
  }
  &--greetings {
    text-align: center;
    margin-bottom: $spacing-3x;
  }
  &--advanced-search {
    text-align: center;

    .ccl__text {
      display: inline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .row {
    --bs-gutter-x: 0.5rem; // $spacing-1x;
  }

  &__fields {
    &__submit {
      width: fit-content;
      &--disabled {
        cursor: not-allowed;
      }

      &__button {
        &:disabled {
          pointer-events: none;
        }
      }

      &__tooltipText {
        color: $color-lightmode-semantic-typography-inverse;
      }
    }
  }
}
